import { PhotoIcon } from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext.js';

import { useOnOff } from '../../../../../hooks/useOnOff';
import { TranslationsText } from '../../../../../internationalization/TranslationsText';
import { useTranslation } from '../../../../../internationalization/useTranslation';
import {
  DialogBody,
  DialogFooter,
  DialogTitle,
} from '../../../../../overlays/Dialog';
import { FormRHFDialogRoot } from '../../../../../overlays/FormRHFDialog';
import { InputFieldRHF } from '../../../../react-hook-form/InputFieldRHF';
import { SubmitButtonRHF } from '../../../../react-hook-form/SubmitButtonRHF';
import { INSERT_IMAGE_COMMAND } from '../../plugins/ImagesPlugin.commands';
import { ToolbarPluginButton } from '../ToolbarPlugin';
import type { TooltipPluginElement } from '../types';

export default function ImagePlugin(): TooltipPluginElement {
  const [isOpenModal, openModal, closeModal] = useOnOff(false);
  const label = useTranslation('richtext.toolbar.image.tooltip.label');

  return (
    <>
      <ToolbarPluginButton
        label={label}
        variant="white"
        id="image"
        onClick={openModal}
        tooltip={<TranslationsText textKey="richtext.toolbar.image.tooltip" />}
      >
        <PhotoIcon className="h-5 w-5" />
      </ToolbarPluginButton>
      <ImageForm isOpen={isOpenModal} closeModal={closeModal} />
    </>
  );
}

interface ImageFormProps {
  isOpen: boolean;
  closeModal: () => void;
}

const defaultImageFormValues = {
  src: '',
  alt: '',
};

function ImageForm(props: ImageFormProps) {
  const { closeModal, isOpen } = props;

  const [editor] = useLexicalComposerContext();

  function onSubmit(values: typeof defaultImageFormValues) {
    if (!values.src) {
      return;
    }

    editor.dispatchCommand(INSERT_IMAGE_COMMAND, values);
    closeModal();
  }

  return (
    <FormRHFDialogRoot
      open={isOpen}
      onOpenChange={(open) => !open && closeModal()}
      defaultValues={defaultImageFormValues}
      onSubmit={onSubmit}
    >
      <DialogTitle>
        <TranslationsText textKey="richtext.toolbar.image.form.header" />
      </DialogTitle>

      <DialogBody className="space-y-5">
        <InputFieldRHF
          data-testid="image-src"
          name="src"
          label={<TranslationsText textKey="richtext.toolbar.image.form.src" />}
        />
        <InputFieldRHF
          data-testid="image-alt"
          name="alt"
          label={<TranslationsText textKey="richtext.toolbar.image.form.alt" />}
        />
      </DialogBody>

      <DialogFooter>
        <SubmitButtonRHF data-testid="image-submit">
          <TranslationsText textKey="richtext.toolbar.image.form.submit" />
        </SubmitButtonRHF>
      </DialogFooter>
    </FormRHFDialogRoot>
  );
}
