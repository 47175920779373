import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { localeContext } from './LocaleProvider.context';

interface LocaleProviderProps {
  locale: string;
  children: ReactNode;
}

export function LocaleProvider(props: LocaleProviderProps) {
  const { children, locale } = props;

  const value = useMemo<string>(() => {
    return locale;
  }, [locale]);

  return (
    <localeContext.Provider value={value}>{children}</localeContext.Provider>
  );
}
