import clsx from 'clsx';
import { useRouter } from 'next/dist/client/router';
import type { ReactElement } from 'react';

import routes from '../../routes';

import Footer from './Footer';
import Navbar from './Navbar';

export default function Layout(props: { children: ReactElement }) {
  const { pathname } = useRouter();
  const route = routes.find((element) => element.to === pathname);
  const showHeader = !route?.embedded;
  const showFooter = !route?.embedded && route?.isFooterVisible;
  // Pages with the Nmrium component dont' have a scroll bar so fixed navbar makes layout harder
  const isHeaderFixed = !route?.isNmrium;

  // The home page is special
  // The margin should be removed in order to use the screen height effectively
  const isHome = route?.to === '/';
  return (
    <div className="flex h-dvh flex-col">
      {showHeader && (
        <header>
          <Navbar fixed={isHeaderFixed} />
        </header>
      )}

      <div
        className={clsx('flex flex-1 flex-col ', {
          'bg-primary-700': !route?.isWhite,
          'bg-white': route?.isWhite,
          'mt-16 sm:mt-12': !isHome && showHeader && isHeaderFixed,
          'min-h-0': route?.isNmrium,
        })}
      >
        {props.children}
      </div>

      {showFooter && (
        <footer>
          <Footer />
        </footer>
      )}
    </div>
  );
}
