import { TableCellsIcon } from '@heroicons/react/24/outline';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext.js';

import { useOnOff } from '../../../../../hooks/useOnOff';
import { TranslationsText } from '../../../../../internationalization/TranslationsText';
import { useTranslation } from '../../../../../internationalization/useTranslation';
import { FormRHFModal } from '../../../../../overlays/FormRHFModal';
import { CheckboxFieldRHF } from '../../../../react-hook-form/CheckboxFieldRHF';
import { InputFieldRHF } from '../../../../react-hook-form/InputFieldRHF';
import { SubmitButtonRHF } from '../../../../react-hook-form/SubmitButtonRHF';
import { INSERT_NEW_TABLE_COMMAND } from '../../@lexical/table/TablePlugin';
import { useRichTextContext } from '../../context/RichTextContext';
import type { RichTextFormatType } from '../../context/RichTextProvider';
import { ToolbarPluginButton } from '../ToolbarPlugin';
import type { TooltipPluginElement } from '../types';

export default function TablePlugin(): TooltipPluginElement {
  const [state] = useRichTextContext();
  const [isOpenModal, openModal, closeModal] = useOnOff(false);

  const label = useTranslation('richtext.toolbar.table.label');

  return (
    <>
      <ToolbarPluginButton
        label={label}
        onClick={openModal}
        id="table"
        tooltip={<TranslationsText textKey="richtext.toolbar.table.tooltip" />}
        variant={!state['table' as RichTextFormatType] ? 'white' : 'secondary'}
      >
        <TableCellsIcon className="h-5 w-5" />
      </ToolbarPluginButton>
      <TableForm isOpen={isOpenModal} closeModal={closeModal} />
    </>
  );
}

interface TableFormProps {
  isOpen: boolean;
  closeModal: () => void;
}

const defaultTableFormValues = {
  columns: 5,
  rows: 5,
  includeColumnAsHeaders: false,
  includeRowAsHeaders: true,
};

function TableForm(props: TableFormProps) {
  const { closeModal, isOpen } = props;

  const [editor] = useLexicalComposerContext();

  function onSubmit(values: typeof defaultTableFormValues) {
    editor.dispatchCommand(INSERT_NEW_TABLE_COMMAND, {
      columns: String(values.columns),
      rows: String(values.rows),
      includeHeaders: {
        columns: values.includeColumnAsHeaders,
        rows: values.includeRowAsHeaders,
      },
    });

    closeModal();
  }

  return (
    // eslint-disable-next-line @typescript-eslint/no-deprecated
    <FormRHFModal
      isOpen={isOpen}
      onSubmit={onSubmit}
      defaultValues={defaultTableFormValues}
      onRequestClose={closeModal}
    >
      <FormRHFModal.Header>
        <TranslationsText textKey="richtext.toolbar.table.form.header" />
      </FormRHFModal.Header>
      <FormRHFModal.Body>
        <InputFieldRHF
          name="columns"
          type="number"
          data-testid="table-columns"
          label={
            <TranslationsText textKey="richtext.toolbar.table.form.columns" />
          }
        />
        <InputFieldRHF
          name="rows"
          type="number"
          data-testid="table-rows"
          label={
            <TranslationsText textKey="richtext.toolbar.table.form.rows" />
          }
        />
        <CheckboxFieldRHF
          name="includeRowAsHeaders"
          label={
            <TranslationsText textKey="richtext.toolbar.table.form.includeRowAsHeaders" />
          }
        />
        <CheckboxFieldRHF
          name="includeColumnAsHeaders"
          data-testid="table-columnAsHeader"
          label={
            <TranslationsText textKey="richtext.toolbar.table.form.includeColumnAsHeaders" />
          }
        />
      </FormRHFModal.Body>
      <FormRHFModal.Footer>
        <SubmitButtonRHF data-testid="table-submit">
          <TranslationsText textKey="richtext.toolbar.table.form.submit" />
        </SubmitButtonRHF>
      </FormRHFModal.Footer>
    </FormRHFModal>
  );
}
