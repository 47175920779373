import clsx from 'clsx';
import type { ReactNode } from 'react';
import { useMemo, useReducer } from 'react';

import type { Color } from '..';

import { Notification } from './Notification';
import type {
  NotificationContext,
  NotificationState,
  ToastNotificationState,
} from './NotificationContext';
import {
  notificationContext,
  notificationsReducer,
} from './NotificationContext';
import { ToastNotification } from './ToastNotification';
import { useNotificationCenter } from './hooks/useNotificationCenter';

export type {
  AddNotification,
  AddToastNotification,
  DeleteNotification,
  NotificationContext,
  NotificationConfig,
  ToastNotificationAction,
  ToastNotificationConfig,
} from './NotificationContext';

type TimeoutConfig = Record<Color, number>;

export function NotificationProvider(props: {
  defaultTimeouts?: Partial<TimeoutConfig>;
  children: ReactNode;
}) {
  const {
    // Extract all properties so that we can useMemo() without requiring the
    // input object to be stable.
    defaultTimeouts: {
      primary = 7000,
      alternative = 7000,
      neutral = 7000,
      danger = 0,
      success = 3000,
      warning = 10000,
    } = {},
    children,
  } = props;

  const timeouts = useMemo(
    () => ({ primary, alternative, neutral, danger, success, warning }),
    [alternative, danger, neutral, primary, success, warning],
  );

  const [state, dispatch] = useReducer(notificationsReducer, {
    notifications: [],
  });

  const hookMethods: Omit<NotificationContext, 'notifications'> =
    useMemo(() => {
      function dismissNotification(payload: string) {
        dispatch({ type: 'CLOSE_NOTIFICATION', payload });
        setTimeout(() => {
          dispatch({ type: 'DELETE_NOTIFICATION', payload });
        }, 200);
      }

      return {
        addNotification(notification, timeout) {
          const id = crypto.randomUUID();
          const type = notification.type || 'neutral';

          if (timeout === undefined) {
            timeout = timeouts[type];
          }

          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
              ...notification,
              id,
              isOpen: true,
              type,
              isToast: false,
              duration: timeout,
            },
          });

          return id;
        },
        addToastNotification(notification, timeout = timeouts.neutral) {
          const id = crypto.randomUUID();

          dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
              ...notification,
              id,
              isToast: true,
              isOpen: true,
              duration: timeout,
            },
          });

          return id;
        },
        dismissNotification,
      };
    }, [timeouts]);

  const contextValue = useMemo(
    () => ({ ...hookMethods, notifications: state.notifications }),
    [state.notifications, hookMethods],
  );

  return (
    <notificationContext.Provider value={contextValue}>
      {children}
    </notificationContext.Provider>
  );
}

export interface ToastNotificationCenterProps {
  position: 'top' | 'bottom';
  className?: string;
  absolute?: boolean;
}

export function ToastNotificationCenter(props: ToastNotificationCenterProps) {
  const { dismissNotification, notifications } = useNotificationCenter();
  const isTop = props.position === 'top';

  return (
    <div
      className={clsx(
        'pointer-events-none inset-0 z-40 flex justify-center py-2',
        {
          'items-end': props.position === 'bottom',
        },
        props.absolute ? 'absolute' : 'fixed',
        props.className,
      )}
    >
      <div className="max-h-full w-full overflow-y-visible sm:w-96">
        <div
          className={clsx('flex gap-2', {
            'flex-col': isTop,
            'flex-col-reverse': !isTop,
          })}
        >
          {notifications
            .filter((element): element is ToastNotificationState =>
              Boolean(element.isToast),
            )
            .map((notification) => {
              return (
                <ToastNotification
                  {...notification}
                  isOpen={notification.isOpen}
                  isTop={isTop}
                  onDismiss={() => dismissNotification(notification.id)}
                  key={notification.group || notification.id}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export interface NotificationCenterProps {
  position: 'top-right' | 'top-left' | 'bottom-right' | 'bottom-left';
  className?: string;
  absolute?: boolean;
}

export function NotificationCenter(props: NotificationCenterProps) {
  const { dismissNotification, notifications } = useNotificationCenter();
  const isTop = props.position.startsWith('top');
  const isLeft = props.position.endsWith('left');

  return (
    <div
      className={clsx(
        'pointer-events-none inset-0 z-40 flex items-center justify-end p-5',
        {
          'sm:items-start': isTop,
          'sm:items-end': !isTop,
          'sm:justify-start': isLeft,
          'sm:justify-end': !isLeft,
        },
        props.absolute ? 'absolute' : 'fixed',
        props.className,
      )}
    >
      <div className="max-h-full w-full overflow-y-visible sm:w-96">
        <div
          className={clsx('flex justify-end gap-5', {
            'flex-col': isTop,
            'flex-col-reverse': !isTop,
          })}
        >
          {notifications
            .filter((element): element is NotificationState => !element.isToast)
            .map((notification) => {
              return (
                <Notification
                  key={notification.id}
                  {...notification}
                  position={isLeft ? 'left' : 'right'}
                  onDismiss={() => dismissNotification(notification.id)}
                  type={notification.type}
                  title={notification.title}
                  isOpen={notification.isOpen}
                  duration={notification.duration}
                >
                  {notification.content}
                </Notification>
              );
            })}
        </div>
      </div>
    </div>
  );
}
