import { useContext } from 'react';

import type { NotificationContext } from '../NotificationContext';
import { notificationContext } from '../NotificationContext';

export function useNotificationCenter(): NotificationContext {
  const context = useContext(notificationContext);

  if (context === null) {
    throw new Error('Missing notification context');
  }

  return context;
}
