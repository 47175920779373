import clsx from 'clsx';
import type { ReactElement, Ref } from 'react';
import { Children, cloneElement, forwardRef } from 'react';

import { useInputId } from '../../../hooks/useInputId';
import type { GroupOptionProps } from '../GroupOption';
import { Label } from '../common';

export interface OptionProps
  extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type'> {
  name: string;
  label: string;
  id?: string;
  description?: string;
}

export const Option = forwardRef(function OptionForwardRef(
  props: OptionProps,
  ref: Ref<HTMLInputElement>,
) {
  const { name, label, id, description, value, checked, ...otherProps } = props;

  const finalId = useInputId(id, `${name}_${String(value)}`);

  return (
    <label htmlFor={finalId}>
      <div
        className={clsx(
          'group flex p-4 has-[div>input:checked]:z-10 has-[div>input:checked]:border-primary-200 has-[div>input:checked]:bg-primary-50',
          otherProps.disabled ? 'cursor-default' : 'cursor-pointer',
        )}
      >
        <div className="flex h-5 items-center">
          <input
            type="radio"
            ref={ref}
            name={name}
            value={value}
            id={finalId}
            className="h-4 w-4 cursor-[inherit] border-neutral-300 text-primary-600 focus:ring-primary-500"
            checked={checked}
            {...otherProps}
          />
        </div>
        <label
          htmlFor={finalId}
          className="ml-3 flex cursor-[inherit] flex-col"
        >
          <span className="block text-sm font-semibold text-neutral-900 group-has-[div>input:checked]:text-primary-900 group-has-[div>input:disabled]:text-neutral-500">
            {label}
          </span>
          {description && (
            <span className="block text-sm text-neutral-500 group-has-[div>input:checked]:text-primary-700">
              {description}
            </span>
          )}
        </label>
      </div>
    </label>
  );
});

export function GroupOptionInternal(props: GroupOptionProps): ReactElement {
  const lastChildIndex = Children.count(props.children) - 1;

  return (
    <div>
      {props.label && (
        <Label
          text={props.label}
          disabled={props.disabled}
          required={props.required}
          hidden={props.hiddenLabel}
        />
      )}
      <div className={clsx({ 'mt-1': !props.hiddenLabel })}>
        {Children.map(props.children, (child, index) => {
          return (
            <div
              key={child.props.id}
              className={clsx('border border-neutral-200', {
                'rounded-tl-md rounded-tr-md': index === 0,
                'border-b-0': index !== lastChildIndex,
                'rounded-bl-md rounded-br border-b': index === lastChildIndex,
              })}
            >
              {props.disabled === true
                ? cloneElement(child, {
                    disabled: props.disabled,
                    name: props.name,
                  })
                : child}
            </div>
          );
        })}
      </div>
    </div>
  );
}
