import type { ReactElement } from 'react';
import { Children } from 'react';

import type { OptionProps } from './internal/GroupOption';
import { GroupOptionInternal, Option } from './internal/GroupOption';

export type { OptionProps } from './internal/GroupOption';

export interface GroupOptionProps {
  name?: string;
  label?: string;
  disabled?: boolean;
  hiddenLabel?: boolean;
  required?: boolean;
  children: ReactElement<OptionProps> | Array<ReactElement<OptionProps>>;
}

export function GroupOption(props: GroupOptionProps) {
  Children.forEach(props.children, (child) => {
    if (child.type !== Option) {
      throw new Error(
        'GroupOption expects children to be GroupOption.Option components only',
      );
    }
  });
  return <GroupOptionInternal {...props} />;
}

GroupOption.Option = Option;
